<template>
  <section class="h-auto challenges-bg">
      <div class="flex justify-center pt-8 lg:pt-20">
        <div v-motion-pop-visible class="flex flex-col justify-center items-center py-10 px-6">
          <img src="../assets/challenges.png" alt="">
          <div class="text-center mt-10 text-xl text-[#FAFF00]" style="font-family: Black Han Sans, sans-serif;">Exclusive challenges only for Code:Mysticbert users!</div>
        </div>
      </div>
 
      <div class="flex flex-wrap justify-center items-center">
   
        <a href="https://roobet.com/game/hacksaw:1620" target="_blank" rel="noopener noreferrer">
          <img src="../assets/challenges-card1.png" alt="challlenges1">
        </a>
       <a href="https://roobet.com/game/hacksaw:1438" target="_blank" rel="noopener noreferrer">
          <img src="../assets/challenges-card2.png" alt="challlenges2">
        </a>
   
      </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      challenges: [],
    };
  },
};
</script>
<style scoped>
.challenges-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
